var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-10 mr-10"},[_c('TitleBar',{attrs:{"loading":_vm.loading,"text":this.$t('company.settings.notifications')}}),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("company.notifications.all_notifications")))]),_c('v-divider',{staticClass:"mb-5"}),_c('v-combobox',{attrs:{"disabled":_vm.loading,"label":_vm.$t('company.notifications_emails'),"chips":"","clearable":"","multiple":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeNotificationEmail(item)}}},'v-chip',attrs,false),[(_vm.getGravatar(item) !== null)?_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":_vm.getGravatar(item)}})],1):_vm._e(),_c('strong',[_vm._v(_vm._s(item))])],1)]}}]),model:{value:(_vm.notification_emails),callback:function ($$v) {_vm.notification_emails=$$v},expression:"notification_emails"}}),_c('v-subheader',[_vm._v(_vm._s(_vm.$t("company.notifications.specific_notifications")))]),_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.notification_types),function(notification,index){return [_c('v-divider',{key:index}),_c('v-list-item',{key:notification.title},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(notification.internal ? _vm.$t(notification.title) : notification.title)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(notification.internal ? _vm.$t(notification.description) : notification.description)}}),_c('v-row',[_vm._l((notification.allowed_methods),function(method){return _c('v-col',{key:method,attrs:{"md":Math.round(12/notification.allowed_methods.length),"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('notifications.methods.'+method),"color":"secondary","hide-details":""},model:{value:(notification.methods[method]),callback:function ($$v) {_vm.$set(notification.methods, method, $$v)},expression:"notification.methods[method]"}})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"disabled":_vm.loading || !notification.methods.email,"label":_vm.$t('company.notifications_emails'),"chips":"","clearable":"","multiple":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeItemNotificationEmail(item, notification.name)}}},'v-chip',attrs,false),[(_vm.getGravatar(item) !== null)?_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":_vm.getGravatar(item)}})],1):_vm._e(),_c('strong',[_vm._v(_vm._s(item))])],1)]}}],null,true),model:{value:(notification.emails),callback:function ($$v) {_vm.$set(notification, "emails", $$v)},expression:"notification.emails"}})],1)],2)],1),_c('v-list-item-action',[(!notification.internal)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-application-brackets ")]):_vm._e()],1)],1)]})],2),_c('v-btn',{staticClass:"mt-2",attrs:{"loading":_vm.loading,"color":"secondary","elevation":"2","type":"submit"}},[_vm._v(_vm._s(_vm.$t("company.notifications.update"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }