<template>
    <div class="ml-10 mr-10">
        <TitleBar :loading="loading" :text="this.$t('company.settings.notifications')"></TitleBar>
        <v-form @submit.prevent="formSubmit">
            <v-subheader>{{ $t("company.notifications.all_notifications") }}</v-subheader>
            <v-divider class="mb-5"></v-divider>

            <v-combobox
                v-model="notification_emails"
                :disabled="loading"
                :label="$t('company.notifications_emails')"
                chips
                clearable
                multiple
                outlined
            >
                <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                        :input-value="selected"
                        close
                        v-bind="attrs"
                        @click="select"
                        @click:close="removeNotificationEmail(item)"
                    >
                        <v-avatar v-if="getGravatar(item) !== null" left>
                            <v-img :src="getGravatar(item)"></v-img>
                        </v-avatar>
                        <strong>{{ item }}</strong>
                    </v-chip>
                </template>
            </v-combobox>

            <v-subheader>{{ $t("company.notifications.specific_notifications") }}</v-subheader>
            <v-list three-line>
                <template v-for="(notification, index) in notification_types">
                    <v-divider
                        :key="index"
                    ></v-divider>

                    <v-list-item
                        :key="notification.title"
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                v-html="notification.internal ? $t(notification.title) : notification.title"></v-list-item-title>
                            <v-list-item-subtitle
                                v-html="notification.internal ? $t(notification.description) : notification.description"></v-list-item-subtitle>

                            <v-row>
                                <v-col
                                    v-for="method in notification.allowed_methods"
                                    :key="method"
                                    :md="Math.round(12/notification.allowed_methods.length)"
                                    cols="12"
                                >
                                    <v-checkbox
                                        v-model="notification.methods[method]"
                                        :label="$t('notifications.methods.'+method)"
                                        color="secondary"
                                        hide-details
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12">
                                    <v-combobox
                                        v-model="notification.emails"
                                        :disabled="loading || !notification.methods.email"
                                        :label="$t('company.notifications_emails')"
                                        chips
                                        clearable
                                        multiple
                                        outlined
                                    >
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip
                                                :input-value="selected"
                                                close
                                                v-bind="attrs"
                                                @click="select"
                                                @click:close="removeItemNotificationEmail(item, notification.name)"
                                            >
                                                <v-avatar v-if="getGravatar(item) !== null" left>
                                                    <v-img :src="getGravatar(item)"></v-img>
                                                </v-avatar>
                                                <strong>{{ item }}</strong>
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>

                            <!--                            TODO: Let the user manage their preference:-->
                            <!--                            - If the notification should be sent by email, in_app or by push notification (or all of-->
                            <!--                            them)-->
                            <!--                            - Let user specify email address that will only receive this notification type-->

                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon v-if="!notification.internal" color="grey lighten-1">mdi-application-brackets
                            </v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>

            <v-btn
                :loading="loading"
                class="mt-2"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $t("company.notifications.update") }}
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import md5 from "md5";
import Accounting from "@/helpers/Accounting";

export default {
    name: "Notifications",
    components: {TitleBar},
    data() {
        return {
            loading: false,
            notification_emails: [],
            notification_types: []
        };
    },
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.settings"),
                to: "/company/settings",
                exact: true
            },
            {
                text: this.$t("company.settings.notifications"),
                to: "/company/settings/notifications",
                exact: true
            }
        ]);

        this.loading = true;
        Promise.all([
            Accounting.get("/companies/" + this.$store.state.company.uuid + "/settings/notification~emails")
                .then(resp => {
                    this.notification_emails = resp.data.value.split(",");
                })
                .catch(() => {
                }),
            Accounting.get("/notifications/types")
                .then(resp => {
                    this.notification_types = [];
                    for (const type of resp.data.types) {
                        type.methods = {};
                        this.notification_types.push(type);
                    }
                })
        ]).finally(() => {
            let preferences = [];
            for (const notificationType of this.notification_types) {
                preferences.push(Accounting.get("/notifications/preferences/" + notificationType.name)
                    .then(resp => {
                        for (const notificationTypesKey in this.notification_types) {
                            if (this.notification_types[notificationTypesKey].name === notificationType.name) {
                                for (const method of resp.data.methods) {
                                    this.notification_types[notificationTypesKey].methods[method] = true;
                                }

                                this.notification_types[notificationTypesKey].emails = resp.data.emails;
                                console.log("found");
                            }
                        }
                    }));
            }
            Promise.all(preferences).finally(() => {
                this.loading = false;
            });
        });

    },
    methods: {
        removeNotificationEmail(item) {
            this.notification_emails = this.notification_emails.filter(it => it !== item);
        },
        removeItemNotificationEmail(email, item) {
            for (const key in this.notification_types) {
                if (this.notification_types[key].name === item) {
                    this.notification_types[key].emails = this.notification_types[key].emails.filter(it => it !== email);
                }
            }
        },
        getGravatar(email) {
            return "https://www.gravatar.com/avatar/" + md5(email);
        },
        formSubmit() {
            this.loading = true;
            this.$store.commit("SET_ALERTS", []);

            let promises = [
                Accounting.put("/companies/" + this.$store.state.company.uuid + "/settings/notification~emails", {
                    value: this.notification_emails.join(",")
                })
            ];

            for (const notificationType of this.notification_types) {
                let methods = [];

                for (const methodsKey in notificationType.methods) {
                    if (notificationType.methods[methodsKey]) {
                        methods.push(methodsKey);
                    }
                }

                promises.push(
                    Accounting.put("/notifications/preferences/" + notificationType.name, {
                        "methods": methods,
                        "emails": notificationType.emails ?? []
                    })
                );
            }

            Promise.all(promises)
                .finally(() => {
                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: this.$t("company.updated")
                    }]);
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>

</style>